<template>
  <div class="customer-report-container">
    <div>
      <el-form class="battery-form" :inline="true" :model="search">
        <el-form-item label="发生时间:">
          <el-date-picker
            v-model="search.time"
            type="datetime"
            placeholder="选择日期时间"
            @change="timeChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getData"
            >查询</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="business">
      <div class="day-business">
        <div>【日业务量统计】</div>
        <div ref="dayCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="all-business">
        <div>【业务分布统计】</div>
        <div ref="allCharts" style="height: 300px; width: 100%"></div>
      </div>
    </div>
    <div class="source">
      <div class="line-source">
        <div>【信息来源统计表】</div>
        <div ref="sourceCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="pie-source">
        <div>【信息来源统计表】</div>
        <div ref="pieSourceCharts" style="height: 300px; width: 100%"></div>
      </div>
    </div>
    <div class="return">
      <div class="return-all">
        <div>【退租电池分布】</div>
        <div ref="returnAllCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-return">
        <div>【4840退租原因分布】</div>
        <div ref="returnFourCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-return">
        <div>【6040退租原因分布】</div>
        <div ref="returnSixFourCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-return">
        <div>【6060退租原因分布】</div>
        <div ref="returnSixSixCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-return">
        <div>【7250退租原因分布】</div>
        <div ref="returnSevenCharts" style="height: 300px; width: 100%"></div>
      </div>
    </div>
    <div class="complain">
      <div class="complain-all">
        <div>【抱怨电池分布】</div>
        <div ref="complainAllCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-complain">
        <div>【4840抱怨原因分布】</div>
        <div ref="complainFourCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-complain">
        <div>【6040抱怨原因分布】</div>
        <div
          ref="complainSixFourCharts"
          style="height: 300px; width: 100%"
        ></div>
      </div>
      <div class="four-complain">
        <div>【6060抱怨原因分布】</div>
        <div
          ref="complainSixSixCharts"
          style="height: 300px; width: 100%"
        ></div>
      </div>
      <div class="four-complain">
        <div>【7250抱怨原因分布】</div>
        <div ref="complainSevenCharts" style="height: 300px; width: 100%"></div>
      </div>
    </div>
    <div class="change">
      <div class="change-all">
        <div>【更换电池分布】</div>
        <div ref="changeAllCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-change">
        <div>【4840更换原因分布】</div>
        <div ref="changeFourCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-change">
        <div>【6040更换原因分布】</div>
        <div ref="changeSixFourCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-change">
        <div>【6060更换原因分布】</div>
        <div ref="changeSixSixCharts" style="height: 300px; width: 100%"></div>
      </div>
      <div class="four-change">
        <div>【7250更换原因分布】</div>
        <div ref="changeSevenCharts" style="height: 300px; width: 100%"></div>
      </div>
    </div>
  </div>
</template>
<script>
import "./Index.less";
import axios from "@/utils/axios";
let echarts = require("echarts/lib/echarts");
// 引入柱状图组件
require("echarts/lib/chart/bar");
require("echarts/lib/chart/line");
// 引入提示框和title组件
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
export default {
  data() {
    return {
      search: {
        time: null,
        day: null,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.getDayBusinessData();
      this.gitBusinessAllData();
      this.getSourceData();
      this.getreturnData();
      this.getFourReturnData();
      this.getSixFourReturnData();
      this.getSixSixReturnData();
      this.getSevenReturnData();
      this.getComplainData();
      this.getFourComplainData();
      this.getSixFourComplainData();
      this.getSixSixComplainData();
      this.getSevenComplainData();
      this.getChangeData();
      this.getFourChangeData();
      this.getSixFourChangeData();
      this.getSixSixChangeData();
      this.getSevenChangeData();
    },
    //时间选择
    timeChange(data) {
      if (data) {
        this.search.day = this.DateToStr(data);
      } else {
        this.search.day = null;
      }
    },
    //日期转字符串
    DateToStr(date) {
      var year = date.getFullYear(); //年
      var month = date.getMonth(); //月
      var day = date.getDate(); //日
      var hours = date.getHours(); //时
      var min = date.getMinutes(); //分
      var second = date.getSeconds(); //秒
      return (
        year +
        "-" +
        (month + 1 > 9 ? month + 1 : "0" + (month + 1)) +
        "-" +
        (day > 9 ? day : "0" + day) +
        " " +
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (min > 9 ? min : "0" + min) +
        ":" +
        (second > 9 ? second : "0" + second)
      );
    },
    //获取7250电池更换原因
    getSevenChangeData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          day: this.search.day,
          type: "7250",
          tagType: "换电池",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sevenChXTexts = [];
            let sevenChYDatas = [];
            res.forEach((item) => {
              sevenChXTexts.push(item.reasonText);
              sevenChYDatas.push(item.quantity);
            });
            let changeSevenCharts = echarts.init(this.$refs.changeSevenCharts);
            let sevenChOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sevenChXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sevenChYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              changeSevenCharts.setOption(sevenChOption);
            });
          } else {
            const dom = this.$refs.changeSevenCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取6060电池更换原因
    getSixSixChangeData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          day: this.search.day,
          type: "6060",
          tagType: "换电池",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sixSixChXTexts = [];
            let sixSixChYDatas = [];
            res.forEach((item) => {
              sixSixChXTexts.push(item.reasonText);
              sixSixChYDatas.push(item.quantity);
            });
            let changeSixSixCharts = echarts.init(
              this.$refs.changeSixSixCharts
            );
            let sixSixChOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sixSixChXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sixSixChYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              changeSixSixCharts.setOption(sixSixChOption);
            });
          } else {
            const dom = this.$refs.changeSixSixCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取6040电池更换原因
    getSixFourChangeData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          day: this.search.day,
          type: "6040",
          tagType: "换电池",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sixFourChXTexts = [];
            let sixFourChYDatas = [];
            res.forEach((item) => {
              sixFourChXTexts.push(item.reasonText);
              sixFourChYDatas.push(item.quantity);
            });
            let changeSixFourCharts = echarts.init(
              this.$refs.changeSixFourCharts
            );
            let sixFourChOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sixFourChXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sixFourChYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              changeSixFourCharts.setOption(sixFourChOption);
            });
          } else {
            const dom = this.$refs.changeSixFourCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取4840电池更换原因
    getFourChangeData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "换电池,4840",
          day: this.search.day,
          type: "4840",
          tagType: "换电池",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let fourChXTexts = [];
            let fourChYDatas = [];
            res.forEach((item) => {
              fourChXTexts.push(item.reasonText);
              fourChYDatas.push(item.quantity);
            });
            let changeFourCharts = echarts.init(this.$refs.changeFourCharts);
            let fourChOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: fourChXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: fourChYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              changeFourCharts.setOption(fourChOption);
            });
          } else {
            const dom = this.$refs.changeFourCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取更换电池统计
    getChangeData() {
      axios
        .post(`/api/customerServiceCenter/weekly/returnTypeWorks`, {
          id: "换电池",
          day: this.search.day,
          tagType: "换电池",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let chXTexts = [];
            let chYDatas = [];
            res.forEach((item) => {
              chXTexts.push(item.batteryType);
              chYDatas.push(item.quantity);
            });
            let changeAllCharts = echarts.init(this.$refs.changeAllCharts);
            const colors = ["#5470C6", "#91CC75", "#EE6666"];
            let chBatteryOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: chXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: chYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              changeAllCharts.setOption(chBatteryOption);
            });
          } else {
            const dom = this.$refs.changeAllCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },

    //获取7250电池抱怨原因
    getSevenComplainData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "抱怨,7250",
          day: this.search.day,
          type: "7250",
          tagType: "抱怨",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sevenCXTexts = [];
            let sevenCYDatas = [];
            res.forEach((item) => {
              sevenCXTexts.push(item.reasonText);
              sevenCYDatas.push(item.quantity);
            });
            let complainSevenCharts = echarts.init(
              this.$refs.complainSevenCharts
            );
            let sevenCOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sevenCXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sevenCYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              complainSevenCharts.setOption(sevenCOption);
            });
          } else {
            const dom = this.$refs.complainSevenCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取6060电池抱怨原因
    getSixSixComplainData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "抱怨,6060",
          day: this.search.day,
          type: "6060",
          tagType: "抱怨",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sixSixCXTexts = [];
            let sixSixCYDatas = [];
            res.forEach((item) => {
              sixSixCXTexts.push(item.reasonText);
              sixSixCYDatas.push(item.quantity);
            });
            let complainSixSixCharts = echarts.init(
              this.$refs.complainSixSixCharts
            );
            let sixSixCOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sixSixCXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sixSixCYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              complainSixSixCharts.setOption(sixSixCOption);
            });
          } else {
            const dom = this.$refs.complainSixSixCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取6040电池抱怨原因
    getSixFourComplainData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "抱怨,6040",
          day: this.search.day,
          type: "6040",
          tagType: "抱怨",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sixFourCXTexts = [];
            let sixFourCYDatas = [];
            res.forEach((item) => {
              sixFourCXTexts.push(item.reasonText);
              sixFourCYDatas.push(item.quantity);
            });
            let complainSixFourCharts = echarts.init(
              this.$refs.complainSixFourCharts
            );
            let sixFourCOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sixFourCXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sixFourCYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              complainSixFourCharts.setOption(sixFourCOption);
            });
          } else {
            const dom = this.$refs.complainSixFourCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取4840电池抱怨原因
    getFourComplainData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "抱怨,4840",
          day: this.search.day,
          type: "4840",
          tagType: "抱怨",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let fourCXTexts = [];
            let fourCYDatas = [];
            res.forEach((item) => {
              fourCXTexts.push(item.reasonText);
              fourCYDatas.push(item.quantity);
            });
            let complainFourCharts = echarts.init(
              this.$refs.complainFourCharts
            );
            let fourCOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: fourCXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: fourCYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              complainFourCharts.setOption(fourCOption);
            });
          } else {
            const dom = this.$refs.complainFourCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取抱怨电池统计
    getComplainData() {
      axios
        .post(`/api/customerServiceCenter/weekly/returnTypeWorks`, {
          id: "抱怨",
          day: this.search.day,
          tagType: "抱怨",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let cXTexts = [];
            let cYDatas = [];
            res.forEach((item) => {
              cXTexts.push(item.batteryType);
              cYDatas.push(item.quantity);
            });
            let complainAllCharts = echarts.init(this.$refs.complainAllCharts);
            const colors = ["#5470C6", "#91CC75", "#EE6666"];
            let cBatteryOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: cXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: cYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              complainAllCharts.setOption(cBatteryOption);
            });
          } else {
            const dom = this.$refs.complainAllCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },

    //获取7250电池退租原因
    getSevenReturnData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "退租,7250",
          day: this.search.day,
          type: "7250",
          tagType: "退租",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sevenXTexts = [];
            let sevenYDatas = [];
            res.forEach((item) => {
              sevenXTexts.push(item.reasonText);
              sevenYDatas.push(item.quantity);
            });
            let returnSevenCharts = echarts.init(this.$refs.returnSevenCharts);
            let sevenOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sevenXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sevenYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              returnSevenCharts.setOption(sevenOption);
            });
          } else {
            const dom = this.$refs.returnSevenCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取6060电池退租原因
    getSixSixReturnData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "退租,6060",
          day: this.search.day,
          type: "6060",
          tagType: "退租",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sixSixXTexts = [];
            let sixSixYDatas = [];
            res.forEach((item) => {
              sixSixXTexts.push(item.reasonText);
              sixSixYDatas.push(item.quantity);
            });
            let returnSixSixCharts = echarts.init(
              this.$refs.returnSixSixCharts
            );
            let sixSixOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sixSixXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sixSixYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              returnSixSixCharts.setOption(sixSixOption);
            });
          } else {
            const dom = this.$refs.returnSixSixCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取6040电池退租原因
    getSixFourReturnData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "退租,6040",
          day: this.search.day,
          type: "6040",
          tagType: "退租",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let sixFourXTexts = [];
            let sixFourYDatas = [];
            res.forEach((item) => {
              sixFourXTexts.push(item.reasonText);
              sixFourYDatas.push(item.quantity);
            });
            let returnSixFourCharts = echarts.init(
              this.$refs.returnSixFourCharts
            );
            let sixFourOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: sixFourXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: sixFourYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              returnSixFourCharts.setOption(sixFourOption);
            });
          } else {
            const dom = this.$refs.returnSixFourCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取4840电池退租原因
    getFourReturnData() {
      axios
        .post(`/api/customerServiceCenter/weekly/singleTypeWorks`, {
          id: "退租,4840",
          day: this.search.day,
          type: "4840",
          tagType: "退租",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let fourXTexts = [];
            let fourYDatas = [];
            res.forEach((item) => {
              fourXTexts.push(item.reasonText);
              fourYDatas.push(item.quantity);
            });
            let returnFourCharts = echarts.init(this.$refs.returnFourCharts);
            let fourOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: fourXTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: fourYDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              returnFourCharts.setOption(fourOption);
            });
          } else {
            const dom = this.$refs.returnFourCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取退租电池统计
    getreturnData() {
      axios
        .post(`/api/customerServiceCenter/weekly/returnTypeWorks`, {
          id: "退租",
          day: this.search.day,
          tagType: "退租",
        })
        .then((res) => {
          if (res && res.length > 0) {
            let xTexts = [];
            let yDatas = [];
            res.forEach((item) => {
              xTexts.push(item.batteryType);
              yDatas.push(item.quantity);
            });
            let returnAllCharts = echarts.init(this.$refs.returnAllCharts);
            const colors = ["#5470C6", "#91CC75", "#EE6666"];
            let batteryOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: xTexts,
              },
              yAxis: {
                type: "value",
              },

              series: [
                {
                  type: "bar",
                  data: yDatas,
                  barWidth: 20,
                },
              ],
            };
            this.$nextTick(() => {
              returnAllCharts.setOption(batteryOption);
            });
          } else {
            const dom = this.$refs.returnAllCharts;
            dom.innerHTML =
              '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
            dom.removeAttribute("_echarts_instance_");
          }
        });
    },
    //获取信息来源数据
    getSourceData() {
      axios
        .post(`/api/customerServiceCenter/weekly/sourceWorks`, {
          day: this.search.day,
        })
        .then((res) => {
          if (res && res.length > 0) {
            let lineXData = [];
            let lineYdataThis = [];
            let lineYdataLast = [];
            let pieData = [];
            res.forEach((item) => {
              lineXData.push(item.sourceText);
              lineYdataThis.push(item.quantity);
              lineYdataLast.push(item.quantityLast);
              let info = {
                value: item.percent,
                name: item.sourceText,
              };
              pieData.push(info);
            });
            let sourceCharts = echarts.init(this.$refs.sourceCharts);
            const colors = ["#5470C6", "#91CC75", "#EE6666"];
            let sourceOption = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: lineXData,
              },
              yAxis: [
                {
                  type: "value",
                  // name: "Evaporation",
                  // min: 0,
                  // max: 250,
                  // position: "right",
                  // axisLine: {
                  //   show: true,
                  //   lineStyle: {
                  //     color: colors[0],
                  //   },
                  // },
                  // axisLabel: {
                  //   formatter: "{value} ml",
                  // },
                },
                {
                  type: "value",
                },
              ],

              series: [
                {
                  name: "本周数据",
                  type: "bar",
                  data: lineYdataThis,
                  barWidth: 20,
                },
                {
                  name: "上周数据",
                  type: "bar",
                  yAxisIndex: 1,
                  data: lineYdataLast,
                  barWidth: 20,
                },
              ],
            };
            let pieSourceCharts = echarts.init(this.$refs.pieSourceCharts);
            let pieOption = {
              title: {
                // text: "信息来源统计表",
                x: "center",
              },
              tooltip: {},
              legend: {
                data: ["百分比"],
              },
              series: [
                {
                  name: "",
                  type: "pie", // 设置图表类型为饼图
                  radius: "55%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
                  // color: ['#37A2DA', '#32C5E9'],
                  label: {
                    normal: {
                      show: true,
                      formatter: "{b}: {c}%",
                    },
                  },
                  // roseType: 'angle',
                  data: pieData,
                },
              ],
            };
            this.$nextTick(() => {
              pieSourceCharts.setOption(pieOption);
              sourceCharts.setOption(sourceOption);
            });
          } else {
            this.$nextTick(() => {
              const dom = this.$refs.sourceCharts;
              dom.innerHTML =
                '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
              dom.removeAttribute("_echarts_instance_");
              const dom1 = this.$refs.pieSourceCharts;
              dom1.innerHTML =
                '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
              dom1.removeAttribute("_echarts_instance_");
            });
          }
        });
    },
    //获取业务分布
    gitBusinessAllData() {
      axios
        .post(`/api/customerServiceCenter/weekly/tagTypeWorks`, {
          day: this.search.day,
        })
        .then((res) => {
          if (res && res.dtoList.length > 0) {
            let dataList = [];
            res.dtoList.forEach((item) => {
              let businessInfo = {
                value: item.quantity,
                name: item.tagTypeText,
              };
              dataList.push(businessInfo);
            });
            let allCharts = echarts.init(this.$refs.allCharts);
            let allOption = {
              title: {
                // text: "业务分布及统计",
                x: "center",
              },
              tooltip: {},
              legend: {
                data: ["数量"],
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },
              series: [
                {
                  name: "",
                  type: "pie", // 设置图表类型为饼图
                  radius: "55%", // 饼图的半径，外半径为可视区尺寸（容器高宽中较小一项）的 55% 长度。
                  // color: ['#37A2DA', '#32C5E9'],
                  label: {
                    normal: {
                      show: true,
                      formatter: "{b}: {c}({d}%)",
                    },
                  },
                  // roseType: 'angle',
                  data: dataList,
                },
              ],
            };
            this.$nextTick(() => {
              allCharts.setOption(allOption);
            });
          } else {
            this.$nextTick(() => {
              const dom = this.$refs.allCharts;
              dom.innerHTML =
                '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
              dom.removeAttribute("_echarts_instance_");
            });
          }
        });
    },
    //获取日业务统计
    getDayBusinessData() {
      axios
        .post(`/api/customerServiceCenter/weekly/dayWorks`, {
          day: this.search.day,
        })
        .then((res) => {
          if (res && res.workDailyList.length > 0) {
            let times = [];
            let dataList = [];
            res.workDailyList.forEach((element) => {
              times.push(element.day);
              dataList.push(element.quantity);
            });
            let dayCharts = echarts.init(this.$refs.dayCharts);
            let option = {};
            option = {
              tooltip: {
                //鼠标悬浮框的提示文字
                trigger: "axis",
              },
              legend: {
                data: ["SOH"],
                textStyle: {
                  //图例文字的样式
                  color: "#000",
                  fontSize: 10,
                },
              },
              dataZoom: [
                {
                  type: "slider",
                  start: 0,
                  end: 100,
                },
                {
                  type: "inside",
                  start: 0,
                  end: 100,
                },
              ],
              xAxis: {
                data: times,
              },
              yAxis: {
                type: "value",
              },
              toolbox: {
                show: true,
                feature: {
                  saveAsImage: {
                    show: true,
                    excludeComponents: ["toolbox"],
                    pixelRatio: 2,
                  },
                },
              },

              series: [
                {
                  data: dataList,
                  type: "line",
                  smooth: true,
                },
              ],
            };
            this.$nextTick(() => {
              dayCharts.setOption(option);
            });
          } else {
            this.$nextTick(() => {
              const dom = this.$refs.dayCharts;
              dom.innerHTML =
                '<div id="BugAmountBar-nodata" style="text-align: center; font-size: 13px;color: #A4A4A4;line-height: 200px">暂无数据</div>';
              dom.removeAttribute("_echarts_instance_");
            });
          }
        })
        .catch((err) => {
          this.$message.error(err);
        });
    },
  },
};
</script>